import 'antd/dist/reset.css'
import 'nprogress/nprogress.css'

import { CloseOutlined } from '@ant-design/icons'
import { BalanceProvider } from '@app/collections/fortress/BalanceContext'
import { BankAccountsProvider } from '@app/collections/fortress/BankAccountsContext'
import { EntityProvider } from '@app/collections/fortress/EntityContext'
import { ProfileProvider } from '@app/collections/fortress/ProfileContext'
import { UserInfoProvider } from '@app/collections/fortress/UserInfoContext'
import { NavBar, Sidebar } from '@app/collections/layout'
import { GloryModal } from '@app/collections/modals-drawers/glory-drawer'
import { Content, If, Layout, Row, Sider, Spin } from '@app/components'
import { ROUTE } from '@app/data'
import { IStore, login, logout, persistor, store, userUpdate } from '@app/redux'
import { analytics, auth, Axios, errorHandler, getSingle } from '@app/services'
import { GlobalStyles } from '@app/styles'
import { getThemeColors, THEME } from '@app/theme'
import { Analytics } from '@vercel/analytics/react'
import { ConfigProvider } from 'antd'
import { logEvent } from 'firebase/analytics'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { StyledBanner } from 'libs/components/layout/elements'
import RainbowKit from 'libs/services/new_wallet/RainboxKit'
import type { AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { AppFonts } from 'public'
import { GloryIcon } from 'public/icons/GloryIcon'
import { useEffect, useRef, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { RecoilRoot } from 'recoil'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'

declare global {
  interface Window {
    Intercom?: any
    intercomSettings?: any
    hj?: (method: 'identify', userId: string | null, userAtributes: Record<string, any>) => void
  }
}

/**
 * Bumps the content up to unglue it from the bottom and give space
 * for other things like intercom widgets when scrolled all the way down
 */
const Gutter = () => <div style={{ height: 128 }} />

// NOTE: Do not write code in this component unless required (use "Main" instead)
export default function App(props: AppProps) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalStyles />
        <Analytics />
        <AppFonts />
        <Main {...props} />
      </PersistGate>
    </Provider>
  )
}

function Main({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(true) // Default to open
  const [screenSize, setScreenSize] = useState(() => {
    const width = window.innerWidth
    return {
      isXL: width > 1280,
      isLarge: width > 900 && width <= 1280,
      isMedium: width > 640 && width <= 900,
      isSmall: width <= 640,
    }
  })

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setScreenSize({
        isXL: width > 1280,
        isLarge: width > 900 && width <= 1280,
        isMedium: width > 640 && width <= 900,
        isSmall: width <= 640,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const route = router.route

  const { theme, user: reduxUser } = useSelector((state: IStore) => state)

  const COLORS = getThemeColors(theme.value)

  NProgress.configure({
    easing: 'ease',
    minimum: 0.3,
    showSpinner: false,
    speed: 800,
  })

  // This useEffect is only for NProgress
  useEffect(() => {
    const start = () => NProgress.start()
    const end = () => NProgress.done()

    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])

  useEffect(() => {
    const unsubscribe = authenticateUser()
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    loadUserData()
  }, [reduxUser.uid, reduxUser.signUpInProgress])

  useEffect(() => {
    redirectBasedOnUser()
  }, [reduxUser.uid, reduxUser.emailVerified, reduxUser.registrationStepNo, reduxUser.frozen, router, route])

  useEffect(() => {
    updateIntercomCustomAttributes()
  }, [reduxUser.fortressAccountId, reduxUser.fortressIdentityId])

  const userAuthenticatedPrevRef = useRef(!!auth.currentUser)

  useEffect(() => {
    updateErrorHandler()
  }, [reduxUser.uid])

  useEffect(() => {
    // Every time the route changes, log a page view with google analytics
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: document.title,
      })
    }
  }, [route])

  const isAuthScreen = Object.values(ROUTE.AUTH).includes(route)
  const isRegistration = [ROUTE.ACCOUNT_REGISTRATION].includes(route)
  const isRoot = [ROUTE.ROOT].includes(route)
  const isSidebarVisible = !isAuthScreen && !isRegistration
  const isFrozen = [ROUTE.FROZEN].includes(route)

  const onSidebarClick = (route: string) => {
    if (reduxUser.frozen == 'True') {
      router.push('/frozen')
    } else if (reduxUser.registrationStepNo < 4) {
      router.push('/account-registration')
    } else {
      router.push(route)
    }
  }

  /**
   * If the user is logged in, and they're on the sign-in or sign-up page, redirect them to the account
   * registration page
   */
  const authenticateUser = () => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { uid, email, emailVerified } = user
        dispatch(login({ uid, email, emailVerified, accessToken: null }))
        const providerId = user.providerData[0]?.providerId
        dispatch(userUpdate({ providerId }))
        updateIntercom()
        updateHotjar()
      } else {
        dispatch(logout())
        updateIntercom()
        updateHotjar()
      }
      setLoading(false)
    })

    return unsubscribe
  }

  const redirectBasedOnUser = async () => {
    const { uid, emailVerified, registrationStepNo, frozen } = reduxUser

    if (uid) {
      if (!emailVerified && !isAuthScreen) {
        router.replace(ROUTE.AUTH.VERIFY_EMAIL)
        return
      } else if (frozen == 'True' && !isFrozen) {
        router.replace('/frozen')
        return
      } else if (registrationStepNo > 3 && isRoot) {
        router.replace(ROUTE.BUY)
        return
      } else if (registrationStepNo <= 3 && !isAuthScreen && !isRegistration) {
        router.replace(ROUTE.ACCOUNT_REGISTRATION)
        return
      } else if (frozen != 'True' && isFrozen) {
        router.replace(ROUTE.BUY)
        return
      }
    } else {
      if (!isAuthScreen) {
        router.replace(ROUTE.AUTH.SIGN_IN)
        return
      }
    }
  }

  const loadUserData = async () => {
    if (reduxUser.uid && !reduxUser.signUpInProgress) {
      const userData = (await getSingle(reduxUser.uid)) as any
      // Updating redux user from firestore db
      dispatch(userUpdate(userData))

      if (userData.isClosed) {
        signOut(auth)
      }
    }
  }

  const updateIntercom = async () => {
    if (Boolean(window.Intercom)) {
      //Update the window.intercom object with the logged in users data
      if (auth.currentUser) {
        const authorization = await auth.currentUser.getIdToken()
        const headers = {
          Authorization: authorization,
        }
        const res = await Axios.get('/api/intercom/user-identity', { headers })
        const hash = res.data
        //const hash = generateUserHash(reduxUser.email)
        window.Intercom('update', {
          api_base: 'https://api-iam.intercom.io',
          app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
          email: auth.currentUser.email, // User's email
          user_hash: hash, // User's hash
          user_id: auth.currentUser.uid, // User's id
          created_at: new Date(auth.currentUser.metadata.creationTime).getTime() / 1000,
          // Add any other user properties as needed
        })

        userAuthenticatedPrevRef.current = true
      } else {
        if (userAuthenticatedPrevRef.current) {
          window.Intercom('shutdown')
        }

        window.Intercom('update', {
          api_base: 'https://api-iam.intercom.io',
          app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
          // Add any other user properties as needed
        })

        userAuthenticatedPrevRef.current = false
      }
    }
  }

  const updateIntercomCustomAttributes = async () => {
    if (Boolean(window.Intercom)) {
      if (auth.currentUser && (reduxUser.fortressIdentityId || reduxUser.fortressAccountId)) {
        window.Intercom('update', {
          'Fortress ID': reduxUser.fortressIdentityId,
          'Fortress Account ID': reduxUser.fortressAccountId,
          'Fortress Link': `https://dashboard.fortressapi.com/identities/${reduxUser.fortressIdentityId}/personal/details`,
        })
      }
    }
  }

  const updateHotjar = async () => {
    // TODO: in rare cases it is possible that the Hotjar script has not been loaded yet,
    // we should wait for it to load or handle the identify call in a different way for the first load
    if (Boolean(window.hj)) {
      if (auth.currentUser) {
        window.hj('identify', auth.currentUser.uid, {
          email: auth.currentUser.email,
        })
      } else {
        window.hj('identify', null, {})
      }
    }
  }

  const updateErrorHandler = () => {
    errorHandler.setUser(reduxUser.uid)
  }

  /* It's setting the background color of the sidebar to a dark color if the theme is dark. */
  const backgroundColor = { background: COLORS.SECONDARY }

  return (
    <ConfigProvider theme={THEME(theme.value, theme.isCompact)}>
      <ThemeProvider theme={{ color: COLORS }}>
        <RecoilRoot>
          <RainbowKit>
            <BalanceProvider userLoading={loading}>
              <UserInfoProvider userLoading={loading}>
                <BankAccountsProvider>
                  <ProfileProvider>
                    <EntityProvider>
                      {loading && (
                        <Layout style={{ height: '100vh' }}>
                          <Row justify={'center'} align={'middle'} style={{ height: '100%' }}>
                            <Spin />
                          </Row>
                        </Layout>
                      )}
                      {!loading && (
                        <Layout>
                          <If condition={!isAuthScreen}>
                            <>
                              <StyledBanner
                                style={{
                                  background: '#aa302c',
                                  color: COLORS.WHITE,
                                  textAlign: 'center',
                                }}
                                message={
                                  screenSize.isXL ? (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                      <GloryIcon style={{ height: '40px', width: '40px' }} />
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          textAlign: 'center',
                                          fontSize: '16px',
                                          fontWeight: 'lighter',
                                          paddingTop: '5px',
                                        }}
                                      >
                                        <span>BANKING IS A FUNDAMENTAL HUMAN RIGHT</span>
                                      </div>
                                      <a
                                        href="https://0xglory.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          background: COLORS.WHITE,
                                          color: '#aa302c',
                                          //padding: '5px 10px',
                                          borderRadius: '4px',
                                          fontWeight: 'bold',
                                          textDecoration: 'none',
                                          paddingTop: '10px',
                                          paddingBottom: '5px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px',
                                          fontSize: '16px',
                                        }}
                                      >
                                        LEARN MORE
                                      </a>
                                    </div>
                                  ) : screenSize.isLarge ? (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                      <GloryIcon style={{ height: '40px', width: '40px' }} />
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          textAlign: 'center',
                                          fontSize: '16px',
                                          fontWeight: 'lighter',
                                          paddingTop: '5px',
                                        }}
                                      >
                                        <span>BANKING IS A FUNDAMENTAL HUMAN RIGHT</span>
                                      </div>
                                      <a
                                        href="https://0xglory.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          background: COLORS.WHITE,
                                          color: '#aa302c',
                                          //padding: '5px 10px',
                                          borderRadius: '4px',
                                          fontWeight: 'bold',
                                          textDecoration: 'none',
                                          paddingTop: '10px',
                                          paddingBottom: '5px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px',
                                          fontSize: '16px',
                                        }}
                                      >
                                        LEARN MORE
                                      </a>
                                    </div>
                                  ) : screenSize.isMedium ? (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                      <GloryIcon style={{ height: '40px', width: '40px' }} />
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          textAlign: 'center',
                                          fontSize: '16px',
                                          fontWeight: 'lighter',
                                          paddingTop: '5px',
                                        }}
                                      >
                                        <span>BANKING IS A FUNDAMENTAL HUMAN RIGHT</span>
                                      </div>
                                      <a
                                        href="https://0xglory.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          background: COLORS.WHITE,
                                          color: '#aa302c',
                                          //padding: '5px 10px',
                                          borderRadius: '4px',
                                          fontWeight: 'bold',
                                          textDecoration: 'none',
                                          paddingTop: '10px',
                                          paddingBottom: '5px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px',
                                          fontSize: '16px',
                                        }}
                                      >
                                        LEARN MORE
                                      </a>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          textAlign: 'center',
                                          fontSize: '11px',
                                          fontWeight: 'lighter',
                                        }}
                                      >
                                        <span>BANKING IS A FUNDAMENTAL HUMAN RIGHT</span>
                                      </div>
                                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <GloryIcon style={{ height: '30px', width: '30px' }} />
                                        <a
                                          href="https://0xglory.com"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            background: COLORS.WHITE,
                                            color: '#aa302c',
                                            padding: '5px 5px',
                                            borderRadius: '4px',
                                            fontWeight: 'bold',
                                            textDecoration: 'none',
                                            //paddingTop: '10px',
                                            fontSize: '11px',
                                          }}
                                        >
                                          LEARN MORE
                                        </a>
                                      </div>
                                    </div>
                                  )
                                }
                                closeIcon={<CloseOutlined style={{ color: COLORS.WHITE, marginRight: '0px' }} />}
                                closable
                              />

                              <GloryModal open={showModal} toggleOpen={() => setShowModal(!showModal)} />

                              <NavBar />
                            </>
                          </If>
                          {/* Layout without sidebar ====================== */}
                          <If condition={!isSidebarVisible}>
                            <Content height="100vh" padding="0">
                              <Component {...pageProps} />
                            </Content>
                          </If>

                          {/* Layout with sidebar ====================== */}
                          <If condition={isSidebarVisible}>
                            <Layout hasSider>
                              <Sider breakpoint="lg" collapsedWidth="0" style={{ ...backgroundColor }} theme={'dark'}>
                                {/*<Menu
                                  selectedKeys={[router.route.slice(1)]}
                                  theme={'dark'}
                                  defaultSelectedKeys={[router.route.slice(1)]}
                                  items={SIDEBAR_MENU_ITEMS(onSidebarClick)}
                                  style={{ ...backgroundColor }}
                                />*/}
                                <Sidebar onClick={onSidebarClick} router={router} backgroundColor={backgroundColor} />
                              </Sider>
                              <Layout>
                                <Content header sidebar>
                                  <Component {...pageProps} />
                                  <Gutter />
                                </Content>
                              </Layout>
                            </Layout>
                          </If>
                        </Layout>
                      )}
                    </EntityProvider>
                  </ProfileProvider>
                </BankAccountsProvider>
              </UserInfoProvider>
            </BalanceProvider>
          </RainbowKit>
        </RecoilRoot>
      </ThemeProvider>
    </ConfigProvider>
  )
}
