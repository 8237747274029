import { IStore } from '@app/redux'
import { getSingle } from '@app/services'
import { GloryIcon } from 'public/icons/GloryIcon'
import React from 'react'
import { useSelector } from 'react-redux'

export const GloryModal = ({ open, toggleOpen }) => {
  const [bankingShownLast, setBankingShownLast] = React.useState(false)
  const [usaLogic, setUsaLogic] = React.useState(null)
  const [showModal, setShowModal] = React.useState(0)

  const { user } = useSelector((state: IStore) => state)

  React.useEffect(() => {
    const skipModal = sessionStorage.getItem('skipModal')

    if (skipModal === 'true') {
      toggleOpen()
      sessionStorage.removeItem('skipModal') // Reset flag so modal appears on future refreshes
      return
    }

    const lastBankingShown = localStorage.getItem('lastBankingShown')

    if (lastBankingShown === 'true') {
      setBankingShownLast(true)
      localStorage.setItem('lastBankingShown', 'false')
    } else {
      setBankingShownLast(false)
      localStorage.setItem('lastBankingShown', 'true')
    }

    // Check how many times the specific usaLogic modal has been shown
    const lastShowModal = localStorage.getItem(`showModal_${usaLogic}`)
    const showCount = lastShowModal ? parseInt(lastShowModal) : 0

    setShowModal(showCount)

    if (showCount < 5) {
      localStorage.setItem(`showModal_${usaLogic}`, String(showCount + 1))
    }
  }, [usaLogic]) // Re-run when usaLogic changes

  React.useEffect(() => {
    const getOGPInfo = async () => {
      const single = await getSingle(user.uid)

      if (single.OGPDepositPaid) {
        //been rewarded, needs to mint
        setUsaLogic(1)
      } else if (single.OGPDeposit) {
        //most likely needs to add payout address
        setUsaLogic(2)
      } else if (single.OGP) {
        //needs to deposit (and add payout address)
        setUsaLogic(3)
      } else if (single.USA) {
        //needs to create OGB account and deposit
        setUsaLogic(4)
      } else {
        setUsaLogic(5)
      }
    }

    getOGPInfo()
  }, [])

  return (
    <>
      {open && usaLogic && showModal < 5 && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            backdropFilter: 'blur(5px)',
            padding: '15px',
          }}
          onClick={toggleOpen}
        >
          <div
            style={{
              padding: '20px',
              paddingTop: '40px',
              borderRadius: '10px',
              boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.5)',
              textAlign: 'center',
              maxWidth: '500px',
              width: '90%',
              position: 'relative',
              border: '2px solid ' + '#05D9FF', // Added 2px border
              background: 'linear-gradient(to right, #000000,rgb(17, 24, 69), #000000)',
            }}
          >
            {usaLogic === 1 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    fontSize: '48px',
                    fontWeight: '900',
                    color: '#ffffff',
                    WebkitTextStroke: '2px #ffffff', // Adds thickness
                    paddingBottom: '20px',
                  }}
                >
                  <span>CLAIM</span>
                  <span>YOUR</span>
                  <span>$GLORY</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center' }}>
                  <a
                    href="https://0xglory.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      //height: '80px',
                      //width: '300px',
                      //background: '#aa302c',
                      color: '#fff',
                      //padding: '10px 20px',
                      borderRadius: '6px',
                      border: '4px solid ' + '#05D9FF', // Added 2px border
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: '26px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: 'inset 0px -3px 5px rgba(0, 0, 0, 0.3)', // Creates a pressed effect
                      textAlign: 'center',
                      lineHeight: '1.2',
                      width: '80%',
                      gap: '20px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <GloryIcon style={{ height: '60px', width: '60px' }} />
                    <span
                      style={{ paddingTop: '10px', fontSize: '30px', fontWeight: 'bold', textTransform: 'uppercase' }}
                    >
                      0xGLORY.com
                    </span>
                  </a>
                </div>
                <p style={{ marginTop: '10px', marginBottom: '0px' }}>
                  <span style={{ color: '#ffffff', fontSize: '12px' }}>$GLORY is a PRC-20 token on PulseChain</span>
                </p>
              </>
            ) : usaLogic === 2 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    fontSize: '48px',
                    fontWeight: '900',
                    color: '#ffffff',
                    WebkitTextStroke: '2px #ffffff', // Adds thickness
                    paddingBottom: '20px',
                  }}
                >
                  <span>ADD YOUR</span>
                  <span>ADDRESS FOR</span>
                  <span>$GLORY</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center' }}>
                  <a
                    href="https://app.0xcoast.com/referrals"
                    onClick={() => sessionStorage.setItem('skipModal', 'true')}
                    //target="_blank"
                    //rel="noopener noreferrer"
                    style={{
                      //height: '80px',
                      //width: '300px',
                      //background: '#aa302c',
                      color: '#fff',
                      //padding: '10px 20px',
                      borderRadius: '6px',
                      border: '4px solid ' + '#05D9FF', // Added 2px border
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: '26px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: 'inset 0px -3px 5px rgba(0, 0, 0, 0.3)', // Creates a pressed effect
                      textAlign: 'center',
                      lineHeight: '1.2',
                      width: '80%',
                      gap: '20px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <GloryIcon style={{ height: '60px', width: '60px' }} />
                    <span
                      style={{ paddingTop: '10px', fontSize: '30px', fontWeight: 'bold', textTransform: 'uppercase' }}
                    >
                      Referrals
                    </span>
                  </a>
                </div>
                <p style={{ marginTop: '10px', marginBottom: '0px' }}>
                  <span style={{ color: '#ffffff', fontSize: '12px' }}>$GLORY is a PRC-20 token on PulseChain</span>
                </p>
              </>
            ) : usaLogic === 3 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    fontSize: '48px',
                    fontWeight: '900',
                    color: '#ffffff',
                    WebkitTextStroke: '2px #ffffff', // Adds thickness
                    paddingBottom: '20px',
                  }}
                >
                  <span>DEPOSIT via</span>
                  <span>OGP FOR</span>
                  <span>$GLORY</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center' }}>
                  <a
                    href="https://app.0xcoast.com/deposit-and-withdraw"
                    onClick={() => sessionStorage.setItem('skipModal', 'true')}
                    //target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      //height: '80px',
                      //width: '300px',
                      //background: '#aa302c',
                      color: '#fff',
                      //padding: '10px 20px',
                      borderRadius: '6px',
                      border: '4px solid ' + '#05D9FF', // Added 2px border
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: '26px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: 'inset 0px -3px 5px rgba(0, 0, 0, 0.3)', // Creates a pressed effect
                      textAlign: 'center',
                      lineHeight: '1.2',
                      width: '80%',
                      gap: '20px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <GloryIcon style={{ height: '60px', width: '60px' }} />
                    <span
                      style={{ paddingTop: '10px', fontSize: '30px', fontWeight: 'bold', textTransform: 'uppercase' }}
                    >
                      DEPOSIT
                    </span>
                  </a>
                </div>
                <p style={{ marginTop: '10px', marginBottom: '0px' }}>
                  <span style={{ color: '#ffffff', fontSize: '12px' }}>$GLORY is a PRC-20 token on PulseChain</span>
                </p>
              </>
            ) : usaLogic === 4 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    fontSize: '48px',
                    fontWeight: '900',
                    color: '#ffffff',
                    WebkitTextStroke: '2px #ffffff', // Adds thickness
                    paddingBottom: '20px',
                  }}
                >
                  <span>GET $GLORY</span>
                  <span>WITH OLD</span>
                  <span>GLORY PAY</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center' }}>
                  <a
                    href="https://account.oldglorybank.com/?jmp=0xCoast&token=dApp"
                    target="_blank"
                    //rel="noopener noreferrer"
                    style={{
                      //height: '80px',
                      //width: '300px',
                      //background: '#aa302c',
                      color: '#fff',
                      //padding: '10px 20px',
                      borderRadius: '6px',
                      border: '4px solid ' + '#05D9FF', // Added 2px border
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: 'inset 0px -3px 5px rgba(0, 0, 0, 0.3)', // Creates a pressed effect
                      textAlign: 'center',
                      lineHeight: '1.2',
                      width: '80%',
                      gap: '20px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                    rel="noreferrer"
                  >
                    <GloryIcon style={{ height: '60px', width: '60px' }} />
                    <span
                      style={{ paddingTop: '10px', fontSize: '20px', fontWeight: 'bold', textTransform: 'uppercase' }}
                    >
                      CREATE ACCOUNT
                    </span>
                  </a>
                </div>
                <p style={{ marginTop: '10px', marginBottom: '0px' }}>
                  <span style={{ color: '#ffffff', fontSize: '12px' }}>$GLORY is a PRC-20 token on PulseChain</span>
                </p>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    fontSize: '48px',
                    fontWeight: '900',
                    color: '#ffffff',
                    WebkitTextStroke: '2px #ffffff', // Adds thickness
                    paddingBottom: '20px',
                  }}
                >
                  {bankingShownLast ? (
                    <>
                      <span>$GLORY</span>
                      <span>REQUIRES</span>
                      <span>SACRIFICE</span>
                    </>
                  ) : (
                    <>
                      <span>BANKING IS A</span>
                      <span>FUNDAMENTAL</span>
                      <span>HUMAN RIGHT</span>
                    </>
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center' }}>
                  <a
                    href="https://0xglory.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      //height: '80px',
                      //width: '300px',
                      //background: '#aa302c',
                      color: '#fff',
                      //padding: '10px 20px',
                      borderRadius: '6px',
                      border: '4px solid ' + '#05D9FF', // Added 2px border
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: '26px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: 'inset 0px -3px 5px rgba(0, 0, 0, 0.3)', // Creates a pressed effect
                      textAlign: 'center',
                      lineHeight: '1.2',
                      width: '80%',
                      gap: '20px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <GloryIcon style={{ height: '60px', width: '60px' }} />
                    <span
                      style={{ paddingTop: '10px', fontSize: '30px', fontWeight: 'bold', textTransform: 'uppercase' }}
                    >
                      LEARN MORE
                    </span>
                  </a>
                </div>
                <p style={{ marginTop: '10px', marginBottom: '0px' }}>
                  <span style={{ color: '#ffffff', fontSize: '12px' }}>$GLORY is a PRC-20 token on PulseChain</span>
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
